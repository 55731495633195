<template>
  <b-form-group
    label="Görüşme Konusu"
    label-for="title"
  >
    <validation-provider
      #default="{ errors }"
      name="Görüşme Konusu"
      rules="required"
    >
      <v-select
        v-model="dataItem.id_interview_subjects"
        :options="interviewSubjects"
        label="title"
        :reduce="interviewSubject => interviewSubject.id"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'InterviewSubject',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['interviewStatuses/dataItem']
    },
    interviewSubjects() {
      return this.$store.getters['interviewSubjects/dataList']
    },
  },
  created() {
    this.getInterviewSubjects()
    localize('tr')
  },
  methods: {
    getInterviewSubjects() {
      this.$store.dispatch('interviewSubjects/getDataList', {
        select: [
          'interview_subjects.id AS id',
          'interview_subjects.title AS title',
        ],
      })
    },
  },
}
</script>
