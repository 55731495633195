<template>
  <b-form-group
    label="Görüşme Durumu"
    label-for="title"
  >
    <validation-provider
      #default="{ errors }"
      name="Görüşme Durumu"
      rules="required"
    >
      <v-select
        v-model="dataItem.status"
        :options="statuses"
        label="title"
        :reduce="status => status.id"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'Status',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
      statuses: [
        {
          id: '0',
          title: 'Görüşme Açık',
        },
        {
          id: '1',
          title: 'Görüşme Kapalı',
        },
      ],
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['interviewStatuses/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
